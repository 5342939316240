import { default as datasourcesU5cspMRs7iMeta } from "/opt/buildhome/repo/pages/datasources.vue?macro=true";
import { default as backgroundlWuRrHZWPbMeta } from "/opt/buildhome/repo/pages/index/new-research/background.vue?macro=true";
import { default as index037WgCWIdMMeta } from "/opt/buildhome/repo/pages/index/new-research/index.vue?macro=true";
import { default as market_45analysis8eqosuW5jzMeta } from "/opt/buildhome/repo/pages/index/new-research/market-analysis.vue?macro=true";
import { default as regulatory_45and_45legal_45feasibilityAlvpRyb463Meta } from "/opt/buildhome/repo/pages/index/new-research/regulatory-and-legal-feasibility.vue?macro=true";
import { default as background8GksUdgVYhMeta } from "/opt/buildhome/repo/pages/index/new-research/results/background.vue?macro=true";
import { default as detailsZOZ04G5l4AMeta } from "/opt/buildhome/repo/pages/index/new-research/results/index/details.vue?macro=true";
import { default as indexSHh7DQyOmwMeta } from "/opt/buildhome/repo/pages/index/new-research/results/index/index.vue?macro=true";
import { default as indexnakwwPbdTyMeta } from "/opt/buildhome/repo/pages/index/new-research/results/index.vue?macro=true";
import { default as market_45analysisqDT5MfeDf4Meta } from "/opt/buildhome/repo/pages/index/new-research/results/market-analysis.vue?macro=true";
import { default as oldw0zDLlYDDqMeta } from "/opt/buildhome/repo/pages/index/new-research/results/old.vue?macro=true";
import { default as references9zi0lud7wrMeta } from "/opt/buildhome/repo/pages/index/new-research/results/references.vue?macro=true";
import { default as resultsw6ob3q0XqXMeta } from "/opt/buildhome/repo/pages/index/new-research/results.vue?macro=true";
import { default as select_45topicsaIYVyPE7G5Meta } from "/opt/buildhome/repo/pages/index/new-research/select-topics.vue?macro=true";
import { default as datasourcesENgfgye1uKMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/datasources.vue?macro=true";
import { default as indexkWk3oDuCVZMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/index.vue?macro=true";
import { default as market_45sizing1jD1pWoD4NMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/market-sizing.vue?macro=true";
import { default as new_45indexnySC4l9zNgMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/new-index.vue?macro=true";
import { default as old_45indexOsSCAOz5YhMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/old-index.vue?macro=true";
import { default as trendstYvxUNDzoIMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/trends.vue?macro=true";
import { default as unmet_45needsN5lWNHbfswMeta } from "/opt/buildhome/repo/pages/index/new-research/summary/unmet-needs.vue?macro=true";
import { default as summaryVGopcBo74vMeta } from "/opt/buildhome/repo/pages/index/new-research/summary.vue?macro=true";
import { default as trendsJR7LaCVoy3Meta } from "/opt/buildhome/repo/pages/index/new-research/trends.vue?macro=true";
import { default as new_45researchsFGZzjSxOiMeta } from "/opt/buildhome/repo/pages/index/new-research.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91studyId_93MwyYv5a9crMeta } from "/opt/buildhome/repo/pages/studies/edit/[studyId].vue?macro=true";
import { default as studiesjHVihjnXqcMeta } from "/opt/buildhome/repo/pages/studies.vue?macro=true";
export default [
  {
    name: datasourcesU5cspMRs7iMeta?.name ?? "datasources",
    path: datasourcesU5cspMRs7iMeta?.path ?? "/datasources",
    meta: datasourcesU5cspMRs7iMeta || {},
    alias: datasourcesU5cspMRs7iMeta?.alias || [],
    redirect: datasourcesU5cspMRs7iMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/datasources.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: new_45researchsFGZzjSxOiMeta?.name ?? undefined,
    path: new_45researchsFGZzjSxOiMeta?.path ?? "new-research",
    meta: new_45researchsFGZzjSxOiMeta || {},
    alias: new_45researchsFGZzjSxOiMeta?.alias || [],
    redirect: new_45researchsFGZzjSxOiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research.vue").then(m => m.default || m),
    children: [
  {
    name: backgroundlWuRrHZWPbMeta?.name ?? "index-new-research-background",
    path: backgroundlWuRrHZWPbMeta?.path ?? "background",
    meta: backgroundlWuRrHZWPbMeta || {},
    alias: backgroundlWuRrHZWPbMeta?.alias || [],
    redirect: backgroundlWuRrHZWPbMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/background.vue").then(m => m.default || m)
  },
  {
    name: index037WgCWIdMMeta?.name ?? "index-new-research",
    path: index037WgCWIdMMeta?.path ?? "",
    meta: index037WgCWIdMMeta || {},
    alias: index037WgCWIdMMeta?.alias || [],
    redirect: index037WgCWIdMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/index.vue").then(m => m.default || m)
  },
  {
    name: market_45analysis8eqosuW5jzMeta?.name ?? "index-new-research-market-analysis",
    path: market_45analysis8eqosuW5jzMeta?.path ?? "market-analysis",
    meta: market_45analysis8eqosuW5jzMeta || {},
    alias: market_45analysis8eqosuW5jzMeta?.alias || [],
    redirect: market_45analysis8eqosuW5jzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/market-analysis.vue").then(m => m.default || m)
  },
  {
    name: regulatory_45and_45legal_45feasibilityAlvpRyb463Meta?.name ?? "index-new-research-regulatory-and-legal-feasibility",
    path: regulatory_45and_45legal_45feasibilityAlvpRyb463Meta?.path ?? "regulatory-and-legal-feasibility",
    meta: regulatory_45and_45legal_45feasibilityAlvpRyb463Meta || {},
    alias: regulatory_45and_45legal_45feasibilityAlvpRyb463Meta?.alias || [],
    redirect: regulatory_45and_45legal_45feasibilityAlvpRyb463Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/regulatory-and-legal-feasibility.vue").then(m => m.default || m)
  },
  {
    name: resultsw6ob3q0XqXMeta?.name ?? undefined,
    path: resultsw6ob3q0XqXMeta?.path ?? "results",
    meta: resultsw6ob3q0XqXMeta || {},
    alias: resultsw6ob3q0XqXMeta?.alias || [],
    redirect: resultsw6ob3q0XqXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results.vue").then(m => m.default || m),
    children: [
  {
    name: background8GksUdgVYhMeta?.name ?? "index-new-research-results-background",
    path: background8GksUdgVYhMeta?.path ?? "background",
    meta: background8GksUdgVYhMeta || {},
    alias: background8GksUdgVYhMeta?.alias || [],
    redirect: background8GksUdgVYhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/background.vue").then(m => m.default || m)
  },
  {
    name: indexnakwwPbdTyMeta?.name ?? undefined,
    path: indexnakwwPbdTyMeta?.path ?? "",
    meta: indexnakwwPbdTyMeta || {},
    alias: indexnakwwPbdTyMeta?.alias || [],
    redirect: indexnakwwPbdTyMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsZOZ04G5l4AMeta?.name ?? "index-new-research-results-index-details",
    path: detailsZOZ04G5l4AMeta?.path ?? "details",
    meta: detailsZOZ04G5l4AMeta || {},
    alias: detailsZOZ04G5l4AMeta?.alias || [],
    redirect: detailsZOZ04G5l4AMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/index/details.vue").then(m => m.default || m)
  },
  {
    name: indexSHh7DQyOmwMeta?.name ?? "index-new-research-results-index",
    path: indexSHh7DQyOmwMeta?.path ?? "",
    meta: indexSHh7DQyOmwMeta || {},
    alias: indexSHh7DQyOmwMeta?.alias || [],
    redirect: indexSHh7DQyOmwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/index/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: market_45analysisqDT5MfeDf4Meta?.name ?? "index-new-research-results-market-analysis",
    path: market_45analysisqDT5MfeDf4Meta?.path ?? "market-analysis",
    meta: market_45analysisqDT5MfeDf4Meta || {},
    alias: market_45analysisqDT5MfeDf4Meta?.alias || [],
    redirect: market_45analysisqDT5MfeDf4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/market-analysis.vue").then(m => m.default || m)
  },
  {
    name: oldw0zDLlYDDqMeta?.name ?? "index-new-research-results-old",
    path: oldw0zDLlYDDqMeta?.path ?? "old",
    meta: oldw0zDLlYDDqMeta || {},
    alias: oldw0zDLlYDDqMeta?.alias || [],
    redirect: oldw0zDLlYDDqMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/old.vue").then(m => m.default || m)
  },
  {
    name: references9zi0lud7wrMeta?.name ?? "index-new-research-results-references",
    path: references9zi0lud7wrMeta?.path ?? "references",
    meta: references9zi0lud7wrMeta || {},
    alias: references9zi0lud7wrMeta?.alias || [],
    redirect: references9zi0lud7wrMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/results/references.vue").then(m => m.default || m)
  }
]
  },
  {
    name: select_45topicsaIYVyPE7G5Meta?.name ?? "index-new-research-select-topics",
    path: select_45topicsaIYVyPE7G5Meta?.path ?? "select-topics",
    meta: select_45topicsaIYVyPE7G5Meta || {},
    alias: select_45topicsaIYVyPE7G5Meta?.alias || [],
    redirect: select_45topicsaIYVyPE7G5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/select-topics.vue").then(m => m.default || m)
  },
  {
    name: summaryVGopcBo74vMeta?.name ?? undefined,
    path: summaryVGopcBo74vMeta?.path ?? "summary",
    meta: summaryVGopcBo74vMeta || {},
    alias: summaryVGopcBo74vMeta?.alias || [],
    redirect: summaryVGopcBo74vMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary.vue").then(m => m.default || m),
    children: [
  {
    name: datasourcesENgfgye1uKMeta?.name ?? "index-new-research-summary-datasources",
    path: datasourcesENgfgye1uKMeta?.path ?? "datasources",
    meta: datasourcesENgfgye1uKMeta || {},
    alias: datasourcesENgfgye1uKMeta?.alias || [],
    redirect: datasourcesENgfgye1uKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/datasources.vue").then(m => m.default || m)
  },
  {
    name: indexkWk3oDuCVZMeta?.name ?? "index-new-research-summary",
    path: indexkWk3oDuCVZMeta?.path ?? "",
    meta: indexkWk3oDuCVZMeta || {},
    alias: indexkWk3oDuCVZMeta?.alias || [],
    redirect: indexkWk3oDuCVZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/index.vue").then(m => m.default || m)
  },
  {
    name: market_45sizing1jD1pWoD4NMeta?.name ?? "index-new-research-summary-market-sizing",
    path: market_45sizing1jD1pWoD4NMeta?.path ?? "market-sizing",
    meta: market_45sizing1jD1pWoD4NMeta || {},
    alias: market_45sizing1jD1pWoD4NMeta?.alias || [],
    redirect: market_45sizing1jD1pWoD4NMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/market-sizing.vue").then(m => m.default || m)
  },
  {
    name: new_45indexnySC4l9zNgMeta?.name ?? "index-new-research-summary-new-index",
    path: new_45indexnySC4l9zNgMeta?.path ?? "new-index",
    meta: new_45indexnySC4l9zNgMeta || {},
    alias: new_45indexnySC4l9zNgMeta?.alias || [],
    redirect: new_45indexnySC4l9zNgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/new-index.vue").then(m => m.default || m)
  },
  {
    name: old_45indexOsSCAOz5YhMeta?.name ?? "index-new-research-summary-old-index",
    path: old_45indexOsSCAOz5YhMeta?.path ?? "old-index",
    meta: old_45indexOsSCAOz5YhMeta || {},
    alias: old_45indexOsSCAOz5YhMeta?.alias || [],
    redirect: old_45indexOsSCAOz5YhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/old-index.vue").then(m => m.default || m)
  },
  {
    name: trendstYvxUNDzoIMeta?.name ?? "index-new-research-summary-trends",
    path: trendstYvxUNDzoIMeta?.path ?? "trends",
    meta: trendstYvxUNDzoIMeta || {},
    alias: trendstYvxUNDzoIMeta?.alias || [],
    redirect: trendstYvxUNDzoIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/trends.vue").then(m => m.default || m)
  },
  {
    name: unmet_45needsN5lWNHbfswMeta?.name ?? "index-new-research-summary-unmet-needs",
    path: unmet_45needsN5lWNHbfswMeta?.path ?? "unmet-needs",
    meta: unmet_45needsN5lWNHbfswMeta || {},
    alias: unmet_45needsN5lWNHbfswMeta?.alias || [],
    redirect: unmet_45needsN5lWNHbfswMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/summary/unmet-needs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trendsJR7LaCVoy3Meta?.name ?? "index-new-research-trends",
    path: trendsJR7LaCVoy3Meta?.path ?? "trends",
    meta: trendsJR7LaCVoy3Meta || {},
    alias: trendsJR7LaCVoy3Meta?.alias || [],
    redirect: trendsJR7LaCVoy3Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index/new-research/trends.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: studiesjHVihjnXqcMeta?.name ?? "studies",
    path: studiesjHVihjnXqcMeta?.path ?? "/studies",
    meta: studiesjHVihjnXqcMeta || {},
    alias: studiesjHVihjnXqcMeta?.alias || [],
    redirect: studiesjHVihjnXqcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/studies.vue").then(m => m.default || m),
    children: [
  {
    name: _91studyId_93MwyYv5a9crMeta?.name ?? "studies-edit-studyId",
    path: _91studyId_93MwyYv5a9crMeta?.path ?? "edit/:studyId()",
    meta: _91studyId_93MwyYv5a9crMeta || {},
    alias: _91studyId_93MwyYv5a9crMeta?.alias || [],
    redirect: _91studyId_93MwyYv5a9crMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/studies/edit/[studyId].vue").then(m => m.default || m)
  }
]
  }
]